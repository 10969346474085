import React from 'react';
import './App.css';
import Header from './Header.js';
import Sidebar from './sidebar.js';
import Feed from './Feed.js'

function App() {
  return (
    <div className="app">
      <Header /> 
      
      <div className='app__body'>
      <Sidebar />
      <Feed />
      </div>
      
      {/* Feed */}
      {/* Widgets */}
    
    </div>
  );
}

export default App;
