import React from 'react';
import './sidebar.css';
import { Avatar } from '@mui/material';

function Sidebar() {
    const recentItem = (topic) => (
        <div className='sidebar_ritem'>
            <span className='sidebar_hash'>#</span>
            <p>{topic}</p>
        </div>
    );
  return (
    <div className='sidebar'>

        <div className='sidebar_top'>

            <img src='https://www.visitphilly.com/wp-content/uploads/2023/03/City-Hall-Skyline-by-Elevated-Angles-2200x1237px.jpg' alt='' />
            <Avatar />
            <h2>Rohan Khan</h2>
            <h4>rohan17343@gmail.com</h4>
        </div>

        <div className='sidebar_stats'>
            <div className='sidebar_stat'>
                <p>Who viewed you</p>
                <p className='sidebar_number'>2,623</p>
            </div>
            <div className='sidebar_stat'>
                <p>Views on post</p>
                <p className='sidebar_number'>3,623</p>
            </div>
        </div>
            <div className='sidebar_bottom'>
                <p>Recent</p>
                {recentItem('react js')}
                {recentItem('programming')}
                {recentItem('drexel')}
                {recentItem('rohan khan')}
            </div>

    </div>
  )
}

export default Sidebar
