import React, {useState} from 'react'
import './Feed.css'
import Feedbutton from './feedbutton.js'
import Post from './Post.js'
import CreateIcon from '@mui/icons-material/Create';
import ImageIcon from '@mui/icons-material/Image';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import EventNoteIcon from '@mui/icons-material/EventNote';
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';

function Feed() {

  const [posts, setposts] = useState([]);
  const sendPost = e => {
    e.preventDefault();
  }

  return (
    <div className='feed'>
        <div className='feed_inputContainer'>
            <div className='feed_input'>
            <CreateIcon />
            <form className='input_form'>
                <input type='text' />
                <button type='submit' onClick={sendPost}>Send</button>
            </form>
            </div>

            <div className='feed_buttons'>
            <Feedbutton title='Photo' Icon={ImageIcon} color='#70B5F9' />
            <Feedbutton title='Video' Icon={SubscriptionsIcon} color='#E7A33E' />
            <Feedbutton title='Event' Icon={EventNoteIcon} color='#C0CBCD' />
            <Feedbutton title='Write Article' Icon={CalendarViewDayIcon} color='#7FC15E' />
            </div>
        </div>
      {posts.map((post) => (
        <Post />
      ))}
      <Post  
      name='Rohan'
      description='this is a test'
      message='this works'
      />
    </div>
  )
}

export default Feed
