import React from 'react'
import HeaderOption from './HeaderOption.js'
import './Header.css'
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ChatIcon from '@mui/icons-material/Chat';
import NotificationsIcon from '@mui/icons-material/Notifications';
import headeravatar from './images/Avatar.png'

function Header() {
  return (
    
    <div className="header">

        <div className='header__left'>

            <img src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png" />
            
            <div className='header__search'>
                <SearchIcon />
                <input type='text' size='35' />
            </div>
        </div>



        <div className='header__right'>
            <HeaderOption Icon={HomeIcon} title={'Home'} />
            <HeaderOption Icon={SupervisorAccountIcon} title={'My Network'} />
            <HeaderOption Icon={BusinessCenterIcon} title={'Jobs'} />
            <HeaderOption Icon={ChatIcon} title={'Messaging'} />
            <HeaderOption Icon={NotificationsIcon} title={'Notifications'} />
          <div className='avatarsection'>
            <img src={headeravatar} className='headeravatar'/>
            <h3>Rohan Khan</h3>
          </div>
        
        </div>
        
    </div>
  )
}

export default Header
