import React from 'react'
import './Post.css'
import Feedbutton from './feedbutton.js'
import { Avatar } from '@mui/material'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ChatIcon from '@mui/icons-material/Chat';
import ShareIcon from '@mui/icons-material/Share';
import SendIcon from '@mui/icons-material/Send';


function Post({name, description, message}) {

  return (
    <div className='post'>
      <div className='post_info'>
      <Avatar />
      <div className='post_info_message'>
      <h2>{name}</h2>
      <p>{description}</p>
      </div>
      </div>
      


      <div className='post_message'>
        <div className='message'>
          <p>{message}</p>
        </div>
        <div className='post_buttons'>
        <Feedbutton title='Like' Icon={ThumbUpAltIcon} color='grey' />
        <Feedbutton title='Comment' Icon={ChatIcon} color='grey' />
        <Feedbutton title='Share' Icon={ShareIcon} color='grey' />
        <Feedbutton title='Send' Icon={SendIcon} color='grey' />

        </div>

      </div>
    </div>
  )
}

export default Post
