import React from 'react'
import './feedbutton.css'

function Feedbutton({Icon, title, color}) {
  return (
    <div className='feedbutton'>
      <Icon style={{color : color}}/>
      <h4>{title}</h4>
    </div>
  )
}

export default Feedbutton
